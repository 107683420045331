import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { getAnalytics } from 'firebase/analytics';

// For Firebase JS SDK v7.20.0 and later, measurementId is 1
const firebaseConfig = {
  apiKey: 'AIzaSyC6GGWCjJOqxyXmO6ArRGR1ljJbhSlqO6I',
  authDomain: 'annotatum.firebaseapp.com',
  projectId: 'annotatum',
  storageBucket: 'annotatum.appspot.com',
  messagingSenderId: '310686633958',
  appId: '1:310686633958:web:421afddf2b03856dcf5027',
  measurementId: 'G-QKWBX62C91',
};

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const fdb = firebase.firestore();
