import React, { useEffect, useRef, useState } from 'react';
import fscreen from 'fscreen';

import {
  annotateComposer,
  MediaAnnotationContextProvider,
  playerComposer,
  PlayerContextProvider,
  browser,
  PanZoomContextProvider,
  PanZoomContainer,
  PanZoomContent,
  PanZoomOverlay,
} from '@openreviewtool/complib';
import {
  TimedSketch,
  UserControllerInputs,
} from '@openreviewtool/complib/dist/component/core/AnnotateCanvas/types';

import '@openreviewtool/complib/dist/style.css';
import { MediaInfo } from '@openreviewtool/complib/dist/component/core/MediaPlayer/types';
import { isTablet } from '@openreviewtool/complib/dist/component/utils/browser';

const DEFAULT_UI_ATTRS: UserControllerInputs = {
  mode: 'selection',
  showAnnotation: true,
  shape: 'Rect',
  fontSize: 20,
  color: 'Coral',
  strokeWidth: 10,
  fontFamily: 'Times New Roman',
};

const Viewer = (props: {
  mediaList: MediaInfo[];
  mediaIndex: number;
  setMediaIndex: (i: number) => void;
  annotationList: TimedSketch[][];
  setAnnotationList: (t: TimedSketch[][]) => void;
}) => {
  const [uiState, setUiState] =
    useState<UserControllerInputs>(DEFAULT_UI_ATTRS);

  const fullScreenElement = useRef<HTMLDivElement>(null);

  const setMediaAnnotation = (mediaAnnotations: TimedSketch[]) => {
    const updated = [...props.annotationList];
    updated[props.mediaIndex] = mediaAnnotations;

    props.setAnnotationList(updated);
  };

  return (
    <div ref={fullScreenElement} style={{ backgroundColor: '#333' }}>
      <PlayerContextProvider
        mediaList={props.mediaList}
        mediaIndex={props.mediaIndex}
        setMediaIndex={props.setMediaIndex}
        fullScreen={false}
        setFullScreen={() => {
          if (fscreen.fullscreenElement) {
            fscreen.exitFullscreen();
          } else {
            fscreen.requestFullscreen(fullScreenElement.current!);
          }
        }}
      >
        <MediaAnnotationContextProvider
          mediaAnnotation={props.annotationList[props.mediaIndex]}
          setMediaAnnotation={setMediaAnnotation}
        >
          <PanZoomContextProvider>
            <PanZoomContainer disabled={uiState.mode !== 'panZoom'}>
              <PanZoomContent>
                <playerComposer.Player />
              </PanZoomContent>
              <PanZoomOverlay>
                <annotateComposer.AnnotateCanvas
                  uiState={uiState}
                  setUiState={setUiState}
                />
              </PanZoomOverlay>
            </PanZoomContainer>
            <annotateComposer.AnnotateTools
              uiState={uiState}
              setUiState={setUiState}
            />
          </PanZoomContextProvider>
          <playerComposer.PlayDeck />
        </MediaAnnotationContextProvider>
      </PlayerContextProvider>
    </div>
  );
};

export default Viewer;
