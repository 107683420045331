import React, { useEffect, useRef, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import '../../firebaseSetup';

import { Alert, Button, Snackbar, Typography } from '@mui/material';
import { TimedSketch } from '@openreviewtool/complib/dist/component/core/AnnotateCanvas/types';

import '@openreviewtool/complib/dist/style.css';
import { MediaInfo } from '@openreviewtool/complib/dist/component/core/MediaPlayer/types';

import ShareIcon from '@mui/icons-material/Share';
import Viewer from './Viewer';
import useMediaUrlInput from './hooks/useMediaUrlInput';
import useBrowserMediaUrl from './hooks/useBrowserMediaUrl';
import AnnotatumLogo from '../../logo.svg';

import './style.css';
import { browser } from '@openreviewtool/complib';

const Annotatum = () => {
  const [annotationList, setAnnotationList] = useState<TimedSketch[][]>([]);
  const [mediaIndex, setMediaIndex] = useState<number>();
  const [mediaList, setMediaList] = useState<MediaInfo[]>([]);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { mediaInfo, isSearching, urlInput, setInputUrl } =
    useMediaUrlInput(inputRef);
  const { dbMediaInfo, dbMediaAnnotation } = useBrowserMediaUrl();
  const topRef = useRef<HTMLDivElement>(null);
  browser.usePreventDefaultBrowserTouch(topRef, 'touchmove');

  useEffect(() => {
    if (mediaInfo) {
      setMediaList([...mediaList, mediaInfo]);
      setMediaIndex(mediaList.length);
      setAnnotationList([...annotationList, []]);
      inputRef.current?.blur();
    }
  }, [mediaInfo]);

  useEffect(() => {
    if (dbMediaInfo) {
      setMediaList([dbMediaInfo]);
      setInputUrl(dbMediaInfo.url);
      setMediaIndex(0);
      setAnnotationList([dbMediaAnnotation]);
    }
  }, [dbMediaInfo, dbMediaAnnotation]);

  return (
    <div
      ref={topRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'calc(100% - 100px)',
        }}
      >
        <div style={{ height: '32px' }} />
        <div style={{ display: 'flex', flexGrow: 0 }}>
          <div className="logo-background">
            <img src={AnnotatumLogo} alt="Annotatum" height="48" width="48" />
          </div>
          <div style={{ width: '4px' }} />
          {urlInput}
        </div>
        <div style={{ height: '16px' }} />
        <div
          style={{
            position: 'relative',
            borderRadius: '4px',
            border: 'solid 1px #555',
            background: '#222',
            height: '50%',
            flexGrow: 1,
            width: '100%',
          }}
        >
          {mediaList.length !== 0 && (
            <Viewer
              mediaList={mediaList}
              mediaIndex={mediaIndex || 0}
              setMediaIndex={(i) => {
                setMediaIndex(i);
                setInputUrl(mediaList[i].url);
              }}
              annotationList={annotationList}
              setAnnotationList={setAnnotationList}
            />
          )}
        </div>
        <div style={{ height: '10px' }} />
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <div style={{ position: 'absolute', right: '0px' }}>
            <Typography variant="subtitle2">
              <a
                style={{ color: '#aaa' }}
                href="https://github.com/openreviewtool/complib"
              >
                Made with OpenReviewTool
              </a>
            </Typography>
          </div>
          <Button
            style={{
              height: '50px',
              borderRadius: '10px',
            }}
            variant="contained"
            disabled={
              (!mediaInfo && !dbMediaInfo) ||
              annotationList.length === 0 ||
              annotationList[mediaIndex!].length === 0
            }
            size="large"
            onClick={() => {
              shareAnnotated(
                mediaInfo! || dbMediaInfo!,
                annotationList[mediaIndex!],
                setShowSnackbar,
              );
            }}
          >
            <ShareIcon fontSize="large" /> <Typography> Share</Typography>
          </Button>
          <Snackbar
            onClose={() => setShowSnackbar(false)}
            open={showSnackbar}
            autoHideDuration={2000}
          >
            <Alert severity="success" sx={{ width: '100%' }}>
              Copied url for sharing.
            </Alert>
          </Snackbar>
        </div>
        <div style={{ height: '20px' }} />
      </div>
    </div>
  );
};

const shareAnnotated = (
  m: MediaInfo,
  a: TimedSketch[],
  displaySnackbar: (s: boolean) => void,
) => {
  const { url, ...mediaInfo } = m;

  firebase
    .firestore()
    .collection('Annotation')
    .add({
      url,
      mediaInfo,
      sketchList: a,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then((result) => {
      const { origin } = new URL(document.URL);
      const shareUrl = `${origin}/view/${result.id}`;

      // Now, we can write to the clipboard in Safari
      // navigator.clipboard.write([clipboardItem]);

      navigator.clipboard
        .writeText(shareUrl)
        .then(() => {
          displaySnackbar(true);
        })
        .catch(() => {
          prompt('Sharable Url: ', shareUrl);
        });
    });

  // const saveThis = a;
  // console.log('...share annotated', { m, a });
  // firebase
  //   .firestore()
  //   .collection('Media')
  //   .doc('2fBTIrjuaed6LWlpJxSe')
  //   .get()
  //   // .collection('media')
  //   // .where('id', '==', oembed.id)
  //   // .get()
  //   .then((result) => {
  //     console.log('.....result', result);
  //     //setAlreadyAdded(result.size !== 0);
  //   });

  // firebase
  //   .firestore()
  //   .collectionGroup('Annotation')
  //   .where('mediaId', '==', 'abc123md5')
  //   .get()
  //   .then((result) => {
  //     console.log('.....result', result);
  //     //setAlreadyAdded(result.size !== 0);
  //   });
  // firebase
  //   .firestore()
  //   .collection('Test')
  //   .add({ hello: 'spidey world', data: saveThis });
  // .then((result) => {
  //   console.log('.....result', result);
  //   //setAlreadyAdded(result.size !== 0);
  // });
};

export default Annotatum;
