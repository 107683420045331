import React, { useRef, useState } from 'react';

import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';

import '@openreviewtool/complib/dist/style.css';
import { useDebounce } from './useQueryMediaInfo';
import useQueryMediaInfo from './useQueryMediaInfo';
import CancelIcon from '@mui/icons-material/Cancel';

const useMediaUrlInput = (
  inputRef: React.MutableRefObject<HTMLInputElement | null>,
) => {
  const [typedInput, setTypedInput] = useState('');
  const debouncedInput = useDebounce(typedInput);
  const [displayUrl, setDisplayUrl] = useState<string | null>(null);
  const { mediaInfo, isSearching } = useQueryMediaInfo(debouncedInput);

  const urlInput = (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Media URL</InputLabel>
      <OutlinedInput
        inputRef={inputRef}
        autoFocus
        placeholder="Enter images and video URLs. Also supports: YouTube, Vimeo, SoundCloud."
        value={displayUrl || typedInput}
        onChange={(e) => {
          if (e.target.value !== displayUrl) {
            setTypedInput(e.target.value);
            setDisplayUrl(null);
          }
        }}
        label="Media URL"
        inputProps={{
          style: {
            textOverflow: 'ellipsis',
          },
        }}
        endAdornment={
          typedInput !== '' && (
            <InputAdornment position="end">
              <IconButton onClick={() => setTypedInput('')} edge="end">
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </FormControl>
  );

  return { mediaInfo, isSearching, urlInput, setInputUrl: setDisplayUrl };
};

export default useMediaUrlInput;
