import firebase from 'firebase/compat/app';
import {
  getAuth,
  signInAnonymously,
  onAuthStateChanged,
  User,
} from 'firebase/auth';

import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Annotatum from './pages/Annotatum';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { AuthContext } from './context/AuthContext';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(
          `Failed to sign in anonymously: ${errorCode}, ${errorMessage}`,
        );
      });

    onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
      } else {
        // User is signed out
        // ...
      }
    });
  });

  return (
    <AuthContext.Provider value={{ user }}>
      <ThemeProvider theme={darkTheme}>
        <Router>
          <Switch>
            <Route exact={true} path="/">
              <Annotatum />
            </Route>
            <Route exact={true} path="/view/:id">
              <Annotatum />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
