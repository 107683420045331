import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { TimedSketch } from '@openreviewtool/complib/dist/component/core/AnnotateCanvas/types';
import { MediaInfo } from '@openreviewtool/complib/dist/component/core/MediaPlayer/types';
import { useParams } from 'react-router-dom';

export default () => {
  const [dbMediaInfo, setDbMediaInfo] = useState<MediaInfo>();
  const [dbMediaAnnotation, setDbMediaAnnotation] = useState<TimedSketch[]>([]);
  const { id: mediaAnnotationId } = useParams<{ id: string }>();
  useEffect(() => {
    if (mediaAnnotationId) {
      firebase
        .firestore()
        .collection('Annotation')
        .doc(mediaAnnotationId)
        .get()
        .then((result) => {
          if (result.exists) {
            const {
              url,
              mediaInfo: dbMediaInfo,
              sketchList,
            } = result.data() as any;

            setDbMediaInfo({ ...dbMediaInfo, url });
            setDbMediaAnnotation(sketchList);
          }
        });
    }
  }, []);

  return { dbMediaInfo, dbMediaAnnotation };
};
