import React from 'react';
import { User } from 'firebase/auth';

type AuthContextProps = {
  user: User | null;
};

export const AuthContext = React.createContext<AuthContextProps>({
  user: null,
});
