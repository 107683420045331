import { useEffect, useState } from 'react';
import { extract, setRequestOptions } from 'oembed-parser';

import { MediaInfo } from '@openreviewtool/complib/dist/component/core/MediaPlayer/types';
import { RectSize } from '@openreviewtool/complib/dist/component/core/PanZoom/types';

type MediaType = 'video' | 'image' | 'audio';

setRequestOptions({
  headers: {
    // 'user-agent': 'hello, world',
    accept: 'application/json; charset=utf-8',
  },
  responseType: 'json',
  responseEncoding: 'utf8',
  timeout: 6e4,
  maxRedirects: 3,
});

export const useDebounce = (value: string, delay = 500) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

const getMediaSize = (m: MediaInfo): RectSize => {
  if (typeof m.width === 'number' && typeof m.height === 'number') {
    return m;
  } else if (m.provider_name === 'SoundCloud') {
    return { width: 800, height: 600 };
  }

  return { width: 1280, height: 720 };
};

const useQueryMediaInfo = (mediaURL: string) => {
  const [mediaInfo, setMediaInfo] = useState<MediaInfo>();
  const [isSearching, setIsSearching] = useState<boolean>(false);

  let urlObj: URL;
  useEffect(() => {
    try {
      urlObj = new URL(mediaURL);
    } catch {
      return;
    }

    // since there are no expected cross overs can attempt to extract
    // oembed, image, and video at the same time.

    // attempt to parse the data using oembed.
    extract(mediaURL)
      .then((result) => {
        setIsSearching(false);
        const mInfo = result as any as MediaInfo;

        const { width, height } = getMediaSize(mInfo);

        setMediaInfo({
          provider_name: mInfo.provider_name,
          type: mInfo.type,
          url: mediaURL,
          width,
          height,
          thumbnail_url: mInfo.thumbnail_url,
          title: mInfo.title,
          author_name: mInfo.author_name,
          author_url: mInfo.author_url,
        });
      })
      .catch((error) => {
        // console.log(`Can not determine oembed url: $(error)`)
      });

    // attempt to load it as an image
    const img = document.createElement('img');
    img.src = mediaURL;
    img.onload = () => {
      setMediaInfo({
        provider_name: '',
        type: 'image',
        url: mediaURL,
        height: img.height,
        width: img.width,

        title: urlObj.pathname.split('/').pop(),
        author_url: urlObj.origin,
      });
    };

    // attempt to load it as an video
    const video = document.createElement('video');
    video.src = mediaURL;
    video.onloadedmetadata = (e) => {
      const videoDOM = e.target as HTMLVideoElement;
      setMediaInfo({
        provider_name: '',
        type: 'video',
        url: mediaURL,
        height: videoDOM.videoHeight,
        width: videoDOM.videoWidth,

        title: urlObj.pathname.split('/').pop(),
        author_url: urlObj.origin,
      });
    };
  }, [mediaURL]);

  return { isSearching, mediaInfo };
};

export default useQueryMediaInfo;
